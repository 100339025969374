import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import Home from './Home';
import ListUsers from './ListUsers';

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/pacote/:idPacote' element={<Home />} />
        <Route path='/users' element={<ListUsers />} />
      </Routes>
    </BrowserRouter>
  )
}