import { Rule } from "antd/es/form";

export const cep: Rule[] = [
  {
    required: true,
    message: 'Informe o CEP'
  }
];

export const endereco: Rule[] = [
  {
    required: true,
    message: 'Informe o endereço'
  }
];

export const bairro: Rule[] = [
  {
    required: true,
    message: 'Informe o bairro'
  }
];

export const numero: Rule[] = [
  {
    required: true,
    message: 'Informe o número'
  }
];

export const estado: Rule[] = [
  {
    required: true,
    message: 'Selecione um estado'
  }
];

export const cidade: Rule[] = [
  {
    required: true,
    message: 'Selecione uma cidade'
  }
];