import { useMemo } from 'react';
import { RightCircleOutlined } from '@ant-design/icons';
import { Card, Row, Col, Form, Input, Select, Button, Typography, Divider, DatePicker } from 'antd';
import { useStepStore } from '../../store/steps.store';
import { Steps } from '../Steps';
import * as validacaoForm from './validation';
import MaskedInput from 'antd-mask-input';
import dayjs from 'dayjs';
import { useCustomerStore } from '../../store/customer.store';
import { PacoteInfo } from '../PacoteInfo';

export function Step01() {
  const [form] = Form.useForm();
  const nextStep = useStepStore(store => store.nexStep);
  const customer = useCustomerStore(store => store);

  function handleSubmit(data: any) {
    useCustomerStore.setState({
      nome_completo: data.nome_completo,
      nome_mae: data.nome_mae,
      celular: data.celular,
      cpf: data.cpf,
      tax_id: data.tax_id,
      email: data.email,
      genero: data.genero,
      data_nascimento: data.data_nascimento,
      data_nascimento_formatted: dayjs(data.data_nascimento).format("YYYY-MM-DD")
    })

    nextStep();
  }

  const cellphoneMask = '(00) 0 0000-0000';
  const phoneMask = '(00) 0000-0000';

  // always memoize dynamic masks
  const mask = useMemo(
    () => [
      {
        mask: cellphoneMask,
        lazy: false,
      },
      {
        mask: phoneMask,
        lazy: false,
      },
    ],
    []
  );

  return (
    <Card>
      <Row justify={'center'}>
        <img src='https://loja.natalviagens.com/wp-content/uploads/2023/10/Group_-1-1.svg' width={100} height={100} alt='Logo Natal Viagens' />
      </Row>
      <Form form={form} layout='vertical' size='large' onFinish={handleSubmit}>
        <PacoteInfo />
        <Row justify={'center'}>
          <Col>
            {/* <Typography.Title style={{ textAlign: 'center' }} level={5}>Dados pessoais</Typography.Title> */}
            <Steps  />
          </Col>
        </Row>
        <Divider />
        <Form.Item name={'nome_completo'} rules={validacaoForm.nomeCompleto} initialValue={customer.nome_completo}>
          <Input placeholder='Nome completo' />
        </Form.Item>
        <Form.Item name={'cpf'} rules={validacaoForm.cpf}  initialValue={customer.cpf}>
          <MaskedInput placeholder='Informe o número do CPF' mask={"000.000.000-00"} />
        </Form.Item>
        <Form.Item name={'tax_id'} rules={validacaoForm.rg} initialValue={customer.tax_id}>
          <Input placeholder='Informe o número do RG' />
        </Form.Item>
        <Form.Item name={'email'} required rules={validacaoForm.email} initialValue={customer.email}>
          <Input placeholder='Informe seu e-mail' />
        </Form.Item>
        <Form.Item name={'celular'} rules={validacaoForm.celular}  initialValue={customer.celular}>
          <MaskedInput
            placeholder='Informe o número do celular'
            mask={mask}
            maskOptions={{
              dispatch: function (appended, dynamicMasked) {
                const isCellPhone = dynamicMasked.unmaskedValue[2] === '9';
                return dynamicMasked.compiledMasks[isCellPhone ? 0 : 1];
              },
            }}
          />
        </Form.Item>
        <Form.Item name={'nome_mae'} rules={validacaoForm.nomeMae} initialValue={customer.nome_mae}>
          <Input placeholder='Nome da mãe' />
        </Form.Item>
        <Form.Item rules={validacaoForm.genero} name='genero' initialValue={customer.genero}>
          <Select placeholder='Gênero'>
            <Select.Option key={'M'}>Masculino</Select.Option>
            <Select.Option key={'F'}>Feminino</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name={'data_nascimento'} rules={validacaoForm.dataNascimento} initialValue={customer.data_nascimento}>
          <DatePicker style={{ width: '100%' }} placeholder="Data de Nascimento" format={"DD/MM/YYYY"} />
        </Form.Item>
        <Divider />
        <Row gutter={8} justify={'end'}>
          <Col lg={12}>
            <Button
              type='primary'
              htmlType='submit'
              icon={<RightCircleOutlined />}
              style={{ width: '100%' }}
              size='large'
            >
              Próximo
            </Button>
          </Col>    
        </Row>
      </Form>
    </Card>
  );
}