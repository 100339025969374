import { Dayjs } from 'dayjs';
import { create } from 'zustand';

interface Props {
  cep: string | null;
  endereco: string | null;
  numero: string | null;
  complemento: string | null;
  bairro: string | null;
  cidade: string | null;
  estado: string | null;
}

export const useAddressStore = create<Props>((set) => ({
  cep: null,
  endereco: null,
  numero: null,
  complemento: null,
  bairro: null,
  cidade: null,
  estado: null
}));