import { create } from 'zustand';

interface Props {
  currentStep: number;
  nexStep: () => void;
  previousStep: () => void;
}

export const useStepStore = create<Props>((set) => ({
  currentStep: 1,
  nexStep: () => set((state) => ({
    currentStep: state.currentStep + 1
  })),
  previousStep: () => set((state) => ({
    currentStep: state.currentStep - 1
  })),
}))