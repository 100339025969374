import { create } from 'zustand';

interface Pacote {
  id: string | null;
  titulo: string | null;
  preco: number | null;
}


interface Props {
  pacote: Pacote | null,
  setPacote: (pacote: Pacote) => void;
}

export const usePacoteStore = create<Props>((set) => ({
  pacote: null,
  setPacote: (pacote: Pacote) => set(() => ({
    pacote
  }))
}));