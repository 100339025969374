import { create } from 'zustand';

export interface Props {
  card_holder_name: string | null;
  card_number: string | null;
  expiration_date: number | null;
  cvv: number | null;
}


export const useCardStore = create<Props>((set) => ({
  card_holder_name: null,
  card_number: null,
  cvv: null,
  expiration_date: null
}))
