import { Col, Row, Table, Tag, Card, Input } from "antd"
import { useCallback, useEffect, useState } from "react"
import { api } from "../service/api"


export default function ListUsers() {
  const [users, setUsers] = useState([])
  const [secret, setSecret] = useState('');

  const loadUsers = useCallback(async () => {
    const { data } = await api.get('/users?token=bmF0YWx2aWFnZW5zX3ZhbGVwYXk=')
    setUsers(data)
  }, [])

  useEffect(() => {
    if (secret === 'valepay') {
      loadUsers()
    }
  
  }, [loadUsers, secret])

  return (
    <Row>
      <Col lg={24}>
        <Card size='small'>
          <Input placeholder="Informe sua secret key para poder acessar" onChange={e => setSecret(e.target.value)} value={secret} style={{ marginBottom: 8 }} />
          {secret === 'valepay' && (
            <Table
              dataSource={users}
              rowKey='id'
              size="small"
              bordered
              scroll={{
                x: "max-content"
              }}
              columns={[
                {
                  title: 'Nome completo',
                  dataIndex: 'name',
                  fixed: 'left',
                  width: 300,
                },
                {
                  title: 'Contato',
                  children: [
                    {
                      title: 'E-mail',
                      dataIndex: 'email',
                      width: 300,
                    },
                    {
                      title: 'Celular',
                      dataIndex: 'cellphone',
                      width: 160,
                    },
                  ]
                },
                {
                  title: 'Documentos',
                  children: [
                    {
                      title: 'CPF',
                      dataIndex: 'document',
                      width: 150,
                    },
                    {
                      title: 'RG',
                      dataIndex: 'tax_id',
                      width: 150,
                    },
                  ]
                },
                {
                  title: 'Localidade',
                  children: [
                    {
                      title: 'Cidade',
                      dataIndex: 'address_city',
                      width: 300,
                    },
                    {
                      title: 'Estado',
                      width: 20,
                      align: 'center',
                      dataIndex: 'address_state',
                    },
                  ]
                },
                {
                  title: 'Pacote',
                  width: 200,
                  children: [
                      {
                        title: 'Código',
                        dataIndex: 'id_pacote',
                        align: 'left',
                        width: 50,
                        render: (id_pacote: string) => <Tag color={'gold'}>Código: {id_pacote}</Tag>
                    },
                    {
                        title: 'Valor',
                        dataIndex: 'valor',
                        align: 'left',
                        width: 150,
                        render: (valor_pacote: number) => <Tag color={'default'}>{Intl.NumberFormat('pt-br', {
                          currency: 'BRL',
                          style: 'currency',
                          maximumFractionDigits: 2
                          }).format(valor_pacote)}</Tag>
                        },
                      {
                        title: 'Pagamento',
                        dataIndex: 'status',
                        width: 150,
                        render: (status: string) => {
                          return status === 'APPROVED' ? <Tag color={'green'}>Aprovado</Tag> : <Tag color={"yellow"}>Pendente</Tag>
                        }
                      },
                  ]
                },
              
              ]}
            />
          )}
        </Card>
      </Col>
    </Row>
  )
}