import { Steps as AntdSteps, Divider, Space, Grid } from 'antd';
import { useStepStore } from '../../store/steps.store';

export function Steps() {
  const currentStep = useStepStore(store => store.currentStep);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  return (
    <Space>
        <AntdSteps
        style={{ paddingTop: 32, position: 'relative', left: xs ? -80 : 0 }}
        direction='horizontal'
        size='small'
        responsive
        progressDot={true}
        current={currentStep - 1}
        items={[
          {
            title: '01',
            description: 'Dados pessoais',
          },
          {
            title: '02',
            description: 'Endereço',
          },
          {
            title: '03',
            description: 'Pagamento',
          },
        ]}
      />
      <Divider />
    </Space>
  );
}