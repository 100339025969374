import { Card, Row, Col, Empty, Space } from 'antd';
import { Step01 } from '../components/Step01';
import { Step02 } from '../components/Step02';
import { Step03 } from '../components/Step03';
import styles from './App.module.css';
import { useStepStore } from '../store/steps.store';
import pacotesViagem from '../pacotes.json';
import { usePacoteStore } from '../store/pacote.store';

export default function Home() {
  const currentStep = useStepStore(store => store.currentStep);
  const setPacote = usePacoteStore(store => store.setPacote);
  const pathname = window.location.href

  const idPacote = pathname.split('/').pop();

  const { pacotes } = pacotesViagem

  const pacote = pacotes.find(pct => pct.idPacote === idPacote);

  if (pacote) {
    setPacote({
      id: pacote.idPacote,
      preco: Number(pacote.preco),
      titulo: pacote.titulo
    })
  }

  function RenderStep(currentStep: number) {
    switch (currentStep) {
      case 1:
        return <Step01 />
      case 2: 
        return <Step02 />
      case 3:
        return <Step03 />
    }
  }

  if (!pacote) {
    return (
      <Card>
        <Empty description="Pacote de viagem não encontrado!" />
      </Card>
    )
  }

  return (
    <main className={styles.container}>
      <Space direction='vertical'>
          <Row>
            <Col lg={24}>
                {RenderStep(currentStep)}
            </Col>
          </Row>
        </Space>
    </main>
  );
}
