import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Card, Row, Col, Form, Divider, Button, Input, Select } from 'antd';
import { useAddressStore } from '../../store/address.store';
import { useStepStore } from '../../store/steps.store';
import { PacoteInfo } from '../PacoteInfo';
import { Steps } from '../Steps';
import * as validacaoForm from './validation';
import localidades from '../../estados-cidades.json';
import { useEffect, useState } from 'react';
import { MaskedInput } from 'antd-mask-input';

export function Step02() {
  const [form] = Form.useForm();
  const [cidades, setCidades] = useState<string[]>([]);
  const nextStep = useStepStore(store => store.nexStep);
  const previousStep = useStepStore(store => store.previousStep);

  const address = useAddressStore(store => store);
  const estado = Form.useWatch('estado', form)

  const estados = localidades.estados.map((estado) => ({
    sigla: estado.sigla,
    nome: estado.nome
  }))

  useEffect(() => {
    const estadoSelecionado = localidades.estados.find(currentEstado => currentEstado.sigla === estado);
    if (estadoSelecionado) {
      setCidades(estadoSelecionado.cidades)
    }
  }, [estado])

  function handleSubmit(data: any) {
    useAddressStore.setState({
      cep: data.cep,
      endereco: data.endereco,
      numero: data.numero,
      complemento: data.complemento,
      bairro: data.bairro,
      cidade: data.cidade,
      estado: data.estado
    })
    nextStep();
  }

  return (
    <Card>
      <Row justify={'center'}>
        <img src='https://loja.natalviagens.com/wp-content/uploads/2023/10/Group_-1-1.svg' width={100} height={100} alt='Logo Natal Viagens' />
      </Row>
      <Row justify={'center'}>
        <Col lg={24}>
          <Form form={form} onFinish={handleSubmit}>
            <PacoteInfo />
            <Row justify={'center'}>
              <Col>
                {/* <Typography.Title style={{ textAlign: 'center' }} level={5}>Endereço do {customer.nome_completo}</Typography.Title> */}
                <Steps />
              </Col>
            </Row>
            <Divider />
            <Form.Item name={'cep'} rules={validacaoForm.cep} initialValue={address.cep}>
              <MaskedInput placeholder='CEP' mask={"00000-000"} />
            </Form.Item>
            <Row gutter={8}>
              <Col lg={19}>
                <Form.Item name={'endereco'} rules={validacaoForm.endereco} initialValue={address.endereco}>
                  <Input placeholder='Endereço' />
                </Form.Item>
              </Col>
              <Col lg={5}>
                <Form.Item name={'numero'} rules={validacaoForm.numero} initialValue={address.numero}>
                  <Input placeholder='Número' />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name={'complemento'} initialValue={address.complemento}>
              <Input placeholder='Complemento' />
            </Form.Item>
            <Form.Item name={'bairro'} rules={validacaoForm.bairro} initialValue={address.bairro}>
              <Input placeholder='Bairro' />
            </Form.Item>
            <Form.Item fieldId='nome' name={'estado'} rules={validacaoForm.estado} initialValue={address.estado}>
              <Select
                showSearch
                allowClear
                onClear={() => {
                  setCidades([])
                }}
                filterOption={(input, option) => {
                  const contains =
                    String(option?.value).toLowerCase().includes(input.toLowerCase()) ||
                    String(option?.children).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(input.toLowerCase())
                  return contains
                }}
                placeholder="Estado"
              >
                {estados.map(estado => (
                  <Select.Option key={estado.sigla}>{estado.nome}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={'cidade'} rules={validacaoForm.cidade} initialValue={address.cidade}>
              <Select
                showSearch
                placeholder="Cidade"
                disabled={!cidades.length}
                filterOption={(input, option) => {
                  const contains = String(option?.children).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(input.toLowerCase())
                  return contains
                }}
              >
                {cidades.map(cidade => (
                  <Select.Option key={cidade}>{cidade}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Row gutter={8}>
              <Col lg={12}>
                <Button
                  type='primary'
                  icon={<LeftCircleOutlined />}
                  style={{ width: '100%' }}
                  size='large'
                  onClick={previousStep}
                >
                  Anterior
                </Button>
              </Col>

              <Col lg={12}>
                <Button
                  type='primary'
                  icon={<RightCircleOutlined />}
                  style={{ width: '100%' }}
                  size='large'
                  htmlType='submit'
              >
                  Próximo
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Card>
  );
}