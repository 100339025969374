import { Rule } from "antd/es/form";


export const card_holder_name: Rule[] = [
    {
    required: true,
    message: 'Nome do titular do cartão é obrigatório',
  },
]

export const card_number: Rule[] = [
  {
    required: true,
    message: 'Número do cartão é obrigatório',
  },
  {
    async validator(_, value: string) {
      const card_number = value?.replace(/\D/g, '').trim()

      if (!card_number || !(card_number.length >= 16) || isNaN(Number(card_number))) {
        throw new Error('Informe um número do cartão válido')
      }
    }
  }
]

export const expiration_date: Rule[] = [
  {
    required: true,
    message: 'Data de expiração é obrigatória',
  },
  {
    async validator(_, value: string) {
      if (value && value.length === 7) {
        const [mes, ano] = value.split('/')

        if (Number(mes) < 1 || Number(mes) > 12) {
          throw new Error('Informe um mês válido!')
        }

        const anoAtual = new Date().getFullYear()
        if (Number(ano) < anoAtual) {
          throw new Error('Informe um ano maior ou igual ao ano atual')
        }
      }
    }
  }
]

export const cvv: Rule[] = [
  {
    required: true,
    message: 'CVV obrigatório',
  },
  {
    min: 3,
    message: 'CVV mínimo 3 digitos'
  }
];
