import { CreditCardOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { Card, Row, Col, Form, Space, Divider, Button, Input, notification, Alert } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { api } from '../../service/api';
import { useAddressStore } from '../../store/address.store';
import { useCardStore, Props } from '../../store/card.store';
import { useCustomerStore } from '../../store/customer.store';
import { usePacoteStore } from '../../store/pacote.store';
import { useStepStore } from '../../store/steps.store';
import { PacoteInfo } from '../PacoteInfo';
import { Steps } from '../Steps';
import * as validacaoForm from './validation';

interface Payment {
  amount: number;
  nome_completo: string;
  nome_mae: string;
  cpf: string;
  tax_id: string;
  email: string;
  genero: 'M' | 'F';
  celular: string;
  data_nascimento_formatted: string;
  cep: string;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  payment: {
    card_holder_name: string;
    card_number: string;
    expiration_date: string;
    cvv: string;
  }
}

export function Step03() {
  const [form] = Form.useForm();

  const card_number = Form.useWatch('card_number', form)
  const expiration_date = Form.useWatch('expiration_date', form)
  const cvv = Form.useWatch('cvv', form)
  const card_holder_name = Form.useWatch('card_holder_name', form)

  const isEmpty = !card_holder_name || !card_number || !expiration_date || !cvv

  const [loading, setLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const customer = useCustomerStore()
  const address = useAddressStore();
  const currentStep = useStepStore(store => store.currentStep);
  const previousStep = useStepStore(store => store.previousStep);
  const pacote = usePacoteStore(store => store.pacote);

  const showPreviousStep = currentStep > 1 && currentStep <= 3;
  const showButtonPayment = currentStep === 3;
  return (
    <Card>
      {paymentSuccess ? (
      <Row>
          <Space direction='vertical'>
            <Row justify={'center'}>
              <img src='https://loja.natalviagens.com/wp-content/uploads/2023/10/Group_-1-1.svg' width={100} height={100} alt='Logo Natal Viagens' />
            </Row>
            <Alert
              message="Pagamento processado com sucesso!"
              description="Agora só aguardar que entramos em contato com mais informações sobre o seu pacote!"
              type="success"
              showIcon
            />
            <PacoteInfo />
            <Row justify={'center'}>
              <Button
              type='primary'
              size='large'
              onClick={() => {
                window.location.href = 'https://loja.natalviagens.com'
              }}
            >
              Voltar para a página inicial
            </Button>
            </Row>
          </Space>
      </Row>
      ) : (
          <Row justify={'center'}>
            <img src='https://loja.natalviagens.com/wp-content/uploads/2023/10/Group_-1-1.svg' width={100} height={100} alt='Logo Natal Viagens' />
        <Col lg={24}>
          <Form form={form} onFinish={async (data: Props) => {
            try {
              useCardStore.setState({
              card_holder_name: data.card_holder_name,
              card_number: data.card_number,
              cvv: data.cvv,
              expiration_date: data.expiration_date
            })

            
            const paymentData = {
              amount: pacote?.preco,
              nome_completo: customer.nome_completo,
              genero: customer.genero,
              celular: customer.celular,
              cpf: customer.cpf,
              email: customer.email,
              nome_mae: customer.nome_mae,
              tax_id: customer.tax_id,
              data_nascimento_formatted: customer.data_nascimento_formatted,
              bairro: address.bairro,
              cep: address.cep,
              cidade: address.cidade,
              complemento: address.complemento,
              estado: address.estado,
              endereco: address.endereco,
              numero: address.numero,
              payment: {
                card_holder_name: data.card_holder_name,
                card_number: data.card_number?.replace(/\D/g, ''),
                cvv: data.cvv,
                expiration_date: data.expiration_date
              },
              valor_pacote: pacote?.preco,
              id_pacote: pacote?.id
            }
            setLoading(true);
            const response = await api.post('/payment', paymentData);
            
              if (response.data.status === 'success') {
              setPaymentSuccess(true)
              notification.success({
                message: 'Pagamento realizado com sucesso!',
                placement: 'top'
              })
            } else {
              notification.warning({
                message: response.data.message,
                placement: 'top'
              })
            }

          
            } catch (error) {
              if (error instanceof AxiosError) {
                notification.warning({
                  message: 'Ocorreu um erro ao processar o pagamento. Verifique os dados e tente novamente!',
                  placement: 'top'
                })
              }
            } finally {
              setLoading(false);
            }
          }}>
            <PacoteInfo />
            <Row justify={'center'}>
              <Col>
                {/* <Typography.Title style={{ textAlign: 'center' }} level={5}>Pagamento</Typography.Title> */}
                <Steps />
              </Col>
            </Row>
            <Divider />
          
            <Form.Item name={'card_number'} rules={validacaoForm.card_number} initialValue={null}>
              <Input placeholder='Número do cartáo' />
            </Form.Item>
            <Row gutter={8}>
              <Col lg={15}>
                <Form.Item name={'expiration_date'} rules={validacaoForm.expiration_date} initialValue={null}>
                  <MaskedInput placeholder='Mês / Ano' mask={"00/0000"} />
                </Form.Item>
              </Col>
              <Col lg={9}>
                <Form.Item name={'cvv'} rules={validacaoForm.cvv} initialValue={null}>
                  <Input placeholder='CVV' maxLength={4}  />
                </Form.Item>
              </Col>
              </Row>
                <Form.Item name={'card_holder_name'} rules={validacaoForm.card_holder_name} initialValue={null}>
              <Input placeholder='Nome do titular do cartáo' />
            </Form.Item>
            <Row gutter={8} style={{ marginTop: 8 }} >
              { showPreviousStep &&
                (
                  <Col lg={12}>
                    <Button
                      type='primary'
                      icon={<LeftCircleOutlined />}
                      style={{ width: '100%' }}
                      size='large'
                    onClick={previousStep}
                    disabled={loading}
                    >
                      Anterior
                    </Button>
                  </Col>
                )
              }
            {showButtonPayment && (
                <Col lg={12}>
                      <Button
                        disabled={isEmpty}
                    danger
                    loading={loading}
                    icon={<CreditCardOutlined />}
                    style={{ width: '100%', color: 'white' }}
                    type='primary'
                    size='large'
                    htmlType='submit'
                  >
                    Confirmar pagamento
                  </Button>
                </Col>
            )}
            </Row>
          </Form>
        </Col>
      </Row>
      )}
      
      
    </Card>
  );
}