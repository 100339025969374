import { Rule } from "antd/es/form";
import { isValidCPF } from "../../utils/validarCPF";

export const nomeCompleto: Rule[] = [
  {
    required: true,
    message: 'Informe o nome completo'
  }
];

export const nomeMae: Rule[] = [
  {
    required: true,
    message: 'Informe o nome completo da mãe'
  }
];

export const genero: Rule[] = [
  {
    required: true,
    message: 'Selecione um gênero'
  }
];

export const email: Rule[] = [
  {
    required: true,
    message: 'Campo de e-mail é obrigatório'
  },
  {
    type: 'email',
    message: 'E-mail inválido!'
  }
];

export const celular: Rule[] = [
  {
    required: true,
    message: 'Número do celular é obrigatório'
  },
];

export const cpf: Rule[] = [
  {
    required: true,
    message: 'CPF obrigatório'
  },
  {
    len: 14,
    message: 'Quantidade de números inválido'
  },
  {
    async validator(_, value: string) {
      if (!isValidCPF(value)) {
        throw new Error('Núemro de CPF inválido!')
      }
    }
  }
];

export const rg: Rule[] = [
  {
    required: true,
    message: 'RG obrigatório'
  },
];

export const dataNascimento: Rule[] = [
  {
    required: true,
    message: 'Data de nascimento obrigatório'
  },
];
