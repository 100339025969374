import { Dayjs } from 'dayjs';
import { create } from 'zustand';

interface Props {
  nome_completo: string | null;
  nome_mae: string | null;
  cpf: string | null;
  tax_id: string | null;
  email: string | null;
  genero: 'M' | 'F' | null;
  celular: string | null;
  data_nascimento: Dayjs | null;
  data_nascimento_formatted: string | null;
}

export const useCustomerStore = create<Props>((set) => ({
  nome_completo: null,
  nome_mae: null,
  cpf: null,
  celular: null,
  genero: null,
  email: null,
  tax_id: null,
  data_nascimento: null,
  data_nascimento_formatted: null
}));