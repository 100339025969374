import { Descriptions } from "antd"
import { usePacoteStore } from "../../store/pacote.store";

export function PacoteInfo() {
  const pacote = usePacoteStore(store => store.pacote);
  const valorBRL = Intl.NumberFormat('pt-br', {
    currency: 'BRL',
    style: 'currency',
    maximumFractionDigits: 2
  }).format(Number(pacote?.preco))

  return (
    <Descriptions bordered column={1} size='small'>
      <Descriptions.Item label="Pacote" >{pacote?.titulo}</Descriptions.Item>
      <Descriptions.Item label="Valor">{valorBRL}</Descriptions.Item>
      <Descriptions.Item label="Parcelado">em 12x</Descriptions.Item>
      <Descriptions.Item label="Viaje entre">Jun/24 e Dez/24</Descriptions.Item>
    </Descriptions>
  );
}